import React from "react";
import styles from "./InProgress.module.css";
import Payment from "../Payment/Payment";
import Transactions from "../Transactions/Transactions";
const InProgress = ({ projectData, paramValue }) => {
    console.log("projectData payments", projectData.transactions)
    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>Project Dashboard</h1>
                </div>
                <div className={styles.content}>
                    <div className={styles.infoGrid}>
                        <div className={`${styles.gridItem} ${styles.span2}`}>
                            <strong>Client Name:</strong>
                            <p>{projectData?.clientName}</p>
                        </div>
                        <div className={styles.gridItem}>
                            <strong>Email:</strong>
                            <p>{projectData?.email}</p>
                        </div>
                        <div className={styles.gridItem}>
                            <strong>Project Name:</strong>
                            <p>{projectData?.projectName}</p>
                        </div>
                        <div className={`${styles.gridItem} ${styles.span2}`}>
                            <strong>Project Details:</strong>
                            <p>{projectData?.projectDescription}</p>
                        </div>
                        <div className={styles.gridItem}>
                            <strong>Project Budget:</strong>
                            <p>{projectData?.projectBudget} Tk</p>
                        </div>
                        <div className={styles.gridItem}>
                            <strong>Paid Amount:</strong>
                            <p>{projectData?.paidAmount} Tk</p>
                        </div>
                    </div>
                </div>
            </div>
            <Payment projectData={projectData} paramValue={paramValue} />
            <Transactions projectData={projectData} />        
        </>
    );
};

export default InProgress;