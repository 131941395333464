import React,{useState} from "react";
import axios from "axios";
import styles from "./AgreementForm.module.css";
import toast from "react-hot-toast";
const AgreementForm = ({projectData,paramValue}) => {

    const [formData, setFormData] = useState({
        address: "",
        email: "",
        phoneNumber: "",
        agreedTerms: false,
      });
      const handleChange = (e) => {
        const value =
          e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setFormData({
          ...formData,
          [e.target.name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.patch(`https://client-management-lilac.vercel.app/api/v1/${paramValue}`, formData);
          toast.success("Form submitted successfully!");
        } catch (error) {
          toast.error("Error submitting the form.");
          console.log(error);
        }
      };
    return (
        <div className="container">
        <div className="left-section">
          <h1 className="form-header">Project Name</h1>
          <h2 className="form-subheader">{projectData?.projectName}</h2>
          <p className="form-input">Client Name: {projectData?.clientName}</p>
          <p className="form-input">Project Description: {projectData?.projectDescription}</p>
        </div>
        <div className="right-section">
          <form onSubmit={handleSubmit}>
            <div className="form-input">
              <label htmlFor="address" className="form-label">Your Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="form-input-field"
                required
              />
            </div>
            <div className="form-input">
              <label htmlFor="email" className="form-label">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-input-field"
                required
              />
            </div>
            <div className="form-input">
              <label htmlFor="phoneNumber" className="form-label">Your Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="form-input-field"
                required
              />
            </div>
            <div className="form-checkbox">
              <input
                type="checkbox"
                id="agreedTerms"
                name="agreedTerms"
                checked={formData.agreedTerms}
                onChange={handleChange}
                className="form-checkbox-input"
                required
              />
              <label htmlFor="agreedTerms" className="form-checkbox-label">
                Agree with all terms and conditions
              </label>
            </div>
            <div className="project-budget">
              <h2>Project Budget: {projectData?.projectBudget} Tk</h2>
            </div>
            <div className="form-submit-container">
              <button
                type="submit"
                disabled={!formData.agreedTerms}
                className='form-submit' 
              > 
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
};
export default AgreementForm;