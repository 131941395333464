import React, { useEffect, useState } from "react";
import "./Client.css";
import axios from "axios";
import toast from "react-hot-toast";

import AgreementForm from "../component/AgreementForm/AgreementFrom";
import InProgress from "../component/InProgress/InProgress";
const Client = () => {
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const paramValue = pathSegments[pathSegments.length - 1];
  console.log("this is paramValue ", paramValue);
  useEffect(() => {
    fetchClientData(paramValue);
  }, []);

  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [formData, setFormData] = useState({
    address: "",
    email: "",
    phoneNumber: "",
    agreedTerms: false,
  });

  const fetchClientData = async (value) => {
    try {
      const { data } = await axios.get(`https://client-management-lilac.vercel.app/api/v1/${value}`);
      setClientData(data);
      console.log("Client Data:", data);
      setFormData({
        email: data?.email || "",
        phoneNumber: data?.phoneNumber || "",
        agreedTerms: false,
      });
      setProjectData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientData(paramValue);
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <h1 className="loading-text">Loading...</h1>
      </div>
    );
  }

  return (
    <>
      {projectData?.projectStatus === "pending" ? (
        <AgreementForm paramValue={paramValue} projectData={projectData} />
      ) : projectData?.projectStatus === "completed" ? (
        <div className="right-section">Completed</div>
      ) : (
        <InProgress paramValue={paramValue} projectData={projectData} />
      )}
    </>
  );
};

export default Client;
