import React, { useState } from "react";
import styles from "./Payment.module.css";
import axios from "axios";
import toast from "react-hot-toast";
const Payment = ({ projectData ,paramValue}) => {
    const [formData, setFormData] = useState({
        _id: paramValue,
        paymentMethod: '',
        amount: 0,
        accountNumber: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        console.log(formData._id)
        try {
            const response = await axios.post(`https://client-management-lilac.vercel.app/api/makePayment`, formData);
            // Handle response
            console.log("Response data: ", response.data);
            toast.success("Form submitted successfully!");
          } catch (error) {
            toast.error("Error submitting the form.");
            console.log(error);
          }
        console.log("Payment Data: ", formData);
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>Make a Payment</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                    <label htmlFor="paymentMethod" className={styles.formLabel}>Payment Method</label>
                    <select 
                        id="paymentMethod" 
                        name="paymentMethod" 
                        value={formData.paymentMethod} 
                        onChange={handleChange} 
                        className={styles.formControl}
                        required
                    >
                        <option value="">Select a payment method</option>
                        <option value="bkash">Bkash</option>
                        <option value="nagad">Nagad</option>
                        <option value="rocket">Rocket</option>
                    </select>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="amount" className={styles.formLabel}>Amount</label>
                    <input 
                        type="number" 
                        id="amount" 
                        name="amount" 
                        value={formData.amount} 
                        onChange={handleChange} 
                        className={styles.formControl}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="accountNumber" className={styles.formLabel}>Account Number</label>
                    <input 
                        type="text" 
                        id="accountNumber" 
                        name="accountNumber" 
                        value={formData.accountNumber} 
                        onChange={handleChange} 
                        className={styles.formControl}
                        required
                    />
                </div>
                <button type="submit" className={styles.submitButton}>Submit Payment</button>
            </form>
        </div>
    );
};

export default Payment;